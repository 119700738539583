/**
 * CanadaCovid19Page (stateful) template that...
 *
 */
import * as React from 'react'
import { useState, useEffect } from 'react'
import * as uuid from 'react-uuid'
import axios from 'axios'
import { Trans } from 'react-i18next'
import Layout from '../components/layout'
import Seo from '../components/seo'
import { GetSummaryResponse } from '../api/endpoints/opencovid/get-summary'
import {
  createEmptyGetSummaryResponse,
  createEmptyGetTimeseriesResponse
} from '../api/endpoints/opencovid/factory'
import OpenCovidDataSource from '../components/dataviz/opencovid-data-source'
import {
  GetTimeseriesAvaccineBody,
  GetTimeseriesCvaccineBody,
  GetTimeseriesResponse,
  GetTimeseriesTestingBody
} from '../api/endpoints/opencovid/get-timeseries'
import { Bar, Line } from 'react-chartjs-2'

const CanadaCovid19Page = ({ pageContext }) => {
  //console.log(pageContext)
  const locName = pageContext.province ? pageContext.province.name : `Canada`
  const locAbbreviation = pageContext.province
    ? pageContext.province.abbreviation.toUpperCase()
    : `canada`
  const pageTitle = pageContext.title || `Charts — Covid19 ${locName} Overview`
  // state
  const [summaryCanadaData, setSummaryCanadaData] = useState(
    createEmptyGetSummaryResponse()
  )
  const [timeSeriesCanadaData, setTimeSeriesCanadaData] = useState(
    createEmptyGetTimeseriesResponse()
  )
  const [isDataLoading, setIsDataLoading] = useState(true)

  const fetchData = async () => {
    // endpoints
    const API_C19_ENDPOINT = 'https://api.opencovid.ca/'
    const API_C19_SUMMARY_ENDPOINT = `${API_C19_ENDPOINT}/summary`
    const API_C19_TIMESERIES_ENDPOINT = `${API_C19_ENDPOINT}/timeseries`
    const API_C19_OTHER_ENDPOINT = `${API_C19_ENDPOINT}/other`

    axios
      .all([
        // fetch cummulative summary
        axios.get(API_C19_SUMMARY_ENDPOINT, {
          params: {
            loc: locAbbreviation,
            version: true
          }
        }),
        // fetch timeseries
        axios.get(API_C19_TIMESERIES_ENDPOINT, {
          params: {
            loc: locAbbreviation,
            version: true
          }
        })
      ])
      .then(
        axios.spread((summaryCanadaResponse, timeSeriesCanadaResponse) => {
          setSummaryCanadaData(summaryCanadaResponse.data)
          setTimeSeriesCanadaData(timeSeriesCanadaResponse.data)
          setIsDataLoading(false)
        })
      )
      .catch((error) => {
        if (error.response) {
          // request made and server responded
          console.log(error.response.data)
          console.log(error.response.status)
          console.log(error.response.headers)
        } else if (error.request) {
          // request made but no response received
          console.log(error.request)
        } else {
          /* something happened in setting up the
          request that triggered an error */
          console.log(error.message)
        }
      })
  }
  /* trigger data fetch after initial render
  by using the useEffect hook */
  useEffect(() => {
    fetchData()
  }, [])

  return (
    <Layout>
      <Seo title="Canada" />
      <main className="l-main">
        <div className="l-constrained text-highlight">
          <h1>{pageTitle}</h1>
          {isDataLoading ? (
            <h2>
              <Trans i18nKey="common.msgs.loading">Loading...</Trans>
            </h2>
          ) : null}

          {
            // display GetSummary data
            <section key={uuid()}>
              {
                // display summary (cases)
                summaryCanadaData?.summary?.length > 0 && (
                  <article key={uuid()}>
                    <h2>{locName} — Cumulative Covid19 Cases Summary</h2>
                    <Bar
                      type="bar"
                      data={parseSummaryCases(summaryCanadaData)}
                      width={100}
                      height={50}
                      options={{
                        maintainAspectRatio: true,
                        plugins: {
                          title: {
                            display: true,
                            text: `${locName} — Cumulative Covid19 Cases Summary`,
                            position: 'bottom'
                          }
                        }
                      }}
                    />
                    <OpenCovidDataSource {...summaryCanadaData} />
                  </article>
                )
              }
              {
                // display summary (testing & vaccine)
                summaryCanadaData?.summary?.length > 0 && (
                  <article key={uuid()}>
                    <h2>
                      {locName} — Cumulative Covid19 Testing & Vaccination
                      Summary
                    </h2>
                    <Bar
                      type="bar"
                      data={parseSummaryVaccination(summaryCanadaData)}
                      width={100}
                      height={50}
                      options={{
                        maintainAspectRatio: true,
                        plugins: {
                          title: {
                            display: true,
                            text: `${locName} — Cumulative Covid19 Testing & Vaccination Summary`,
                            position: 'bottom'
                          }
                        }
                      }}
                    />
                    <OpenCovidDataSource {...summaryCanadaData} />
                  </article>
                )
              }
            </section>
          }

          {
            // display GetTimeseries data
            <section key={uuid()}>
              {
                // display active cases
                timeSeriesCanadaData?.active?.length > 0 && (
                  <article key={uuid()}>
                    <h2>{locName} — Covid19 Cases Over Time</h2>
                    <Line
                      type="line"
                      data={parseActiveCases(timeSeriesCanadaData)}
                      options={{
                        //cubicInterpolationMode: "monotone",
                        stepped: false,
                        responsive: true,
                        maintainAspectRatio: true,
                        borderWidth: 1,
                        lineWidth: 1,
                        spanGaps: true,
                        plugins: {
                          title: {
                            display: true,
                            text: `${locName} — Covid19 Cases Over Time`,
                            position: 'bottom'
                          }
                        }
                      }}
                    />
                    <OpenCovidDataSource {...timeSeriesCanadaData} />
                  </article>
                )
              }
              {
                // display testing
                timeSeriesCanadaData?.testing?.length > 0 && (
                  <article key={uuid()}>
                    <h2>{locName} — Covid19 Testing Over Time</h2>
                    <Line
                      type="line"
                      data={parseTesting(timeSeriesCanadaData)}
                      options={{
                        //cubicInterpolationMode: "monotone",
                        stepped: false,
                        responsive: true,
                        maintainAspectRatio: true,
                        borderWidth: 1,
                        lineWidth: 1,
                        spanGaps: true,
                        plugins: {
                          title: {
                            display: true,
                            text: `${locName} — Covid19 Testing Over Time`,
                            position: 'bottom'
                          }
                        }
                      }}
                    />
                    <OpenCovidDataSource {...timeSeriesCanadaData} />
                  </article>
                )
              }
              {
                // display administered vaccine
                timeSeriesCanadaData?.avaccine?.length > 0 && (
                  <article key={uuid()}>
                    <h2>
                      {locName} — Administered Covid19 Vaccination Over Time
                    </h2>
                    <Line
                      type="line"
                      data={parseAvaccine(timeSeriesCanadaData)}
                      options={{
                        //cubicInterpolationMode: "monotone",
                        stepped: false,
                        responsive: true,
                        maintainAspectRatio: true,
                        borderWidth: 2,
                        spanGaps: true,
                        plugins: {
                          title: {
                            display: true,
                            text: `${locName} — Administered Covid19 Vaccination Over Time`,
                            position: 'bottom'
                          }
                        }
                      }}
                    />
                    <OpenCovidDataSource {...timeSeriesCanadaData} />
                  </article>
                )
              }
              {
                // display completed vaccine
                timeSeriesCanadaData?.cvaccine?.length > 0 && (
                  <article key={uuid()}>
                    <h2>{locName} — Completed Covid19 Vaccination Over Time</h2>
                    <Line
                      type="line"
                      data={parseCvaccine(timeSeriesCanadaData)}
                      options={{
                        //cubicInterpolationMode: "monotone",
                        stepped: false,
                        responsive: true,
                        maintainAspectRatio: true,
                        borderWidth: 2,
                        spanGaps: true,
                        plugins: {
                          title: {
                            display: true,
                            text: `${locName} — Completed Covid19 Vaccination Over Time`,
                            position: 'bottom'
                          }
                        }
                      }}
                    />
                    <OpenCovidDataSource {...timeSeriesCanadaData} />
                  </article>
                )
              }
            </section>
          }
          <br />
          <br />
        </div>
      </main>
    </Layout>
  )
}

const parseSummaryCases = (props: GetSummaryResponse): any => {
  //console.log("---- parseSummaryCases: ", props)
  const summary = props?.summary[0]
  const barChartData = {
    labels: ['Cumulative Totals'],
    datasets: [
      {
        label: 'Cases',
        data: [summary.cumulative_cases],
        backgroundColor: 'rgba(135,206,250,0.2)',
        borderColor: 'rgba(135,206,250,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(135,206,250,0.4)',
        hoverBorderColor: 'rgba(135,206,250,1)'
      },
      {
        label: 'Recovered',
        data: [summary.cumulative_recovered],
        backgroundColor: 'rgba(119,221,119,0.2)',
        borderColor: 'rgba(119,221,119,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(119,221,119,0.4)',
        hoverBorderColor: 'rgba(119,221,119,1)'
      },
      {
        label: 'Active',
        data: [summary.active_cases],
        backgroundColor: 'rgba(255,99,132,0.2)',
        borderColor: 'rgba(255,99,132,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(255,99,132,0.4)',
        hoverBorderColor: 'rgba(255,99,132,1)'
      },
      {
        label: 'Deaths',
        data: [summary.cumulative_deaths],
        backgroundColor: 'rgba(128,128,128,0.2)',
        borderColor: 'rgba(128,128,128,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(128,128,128, 0.4)',
        hoverBorderColor: 'rgba(128,128,128, 1)'
      }
    ]
  }
  return barChartData
}

const parseSummaryVaccination = (props: GetSummaryResponse): any => {
  //console.log("---- parseSummaryVaccination: ", props)
  const summary = props?.summary[0]
  const barChartData = {
    labels: ['Cumulative Totals'],
    datasets: [
      {
        label: 'Testing',
        backgroundColor: 'rgba(135,206,250,0.2)',
        borderColor: 'rgba(135,206,250,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(135,206,250,0.4)',
        hoverBorderColor: 'rgba(135,206,250,1)',
        data: [summary.cumulative_testing]
      },
      {
        label: 'Vaccine Distributed',
        backgroundColor: 'rgba(255,99,132,0.2)',
        borderColor: 'rgba(255,99,132,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(255,99,132,0.4)',
        hoverBorderColor: 'rgba(255,99,132,1)',
        data: [summary.cumulative_dvaccine]
      },
      {
        label: 'Vaccine Administered',
        backgroundColor: 'rgba(119,221,119,0.2)',
        borderColor: 'rgba(119,221,119,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(119,221,119,0.4)',
        hoverBorderColor: 'rgba(119,221,119,1)',
        data: [summary.cumulative_avaccine]
      },
      {
        label: 'Vaccine Completed',
        backgroundColor: 'rgba(128,128,128,0.2)',
        borderColor: 'rgba(128,128,128,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(128,128,128, 0.4)',
        hoverBorderColor: 'rgba(128,128,128, 1)',
        data: [summary.cumulative_cvaccine]
      }
    ]
  }
  return barChartData
}

const parseActiveCases = (props: GetTimeseriesResponse): any => {
  //console.log("---- parseActiveCases: ", props)
  const active: number[] = []
  const cases: number[] = []
  const dates: string[] = []
  const deaths: number[] = []
  const recovered: number[] = []
  props.active?.map((item) => {
    active.push(item.active_cases)
    cases.push(item.cumulative_cases)
    dates.push(item.date_active?.trim())
    deaths.push(item.cumulative_deaths)
    recovered.push(item.cumulative_recovered)
  })
  const lineChartData = {
    labels: dates,
    datasets: [
      {
        label: 'Cases',
        data: cases,
        backgroundColor: 'rgba(102, 153, 204, 0.2)',
        borderColor: 'rgba(102, 153, 204, 1)',
        fill: false
      },
      {
        label: 'Recovered',
        data: recovered,
        backgroundColor: 'rgba(102, 204, 153, 0.2)',
        borderColor: 'rgba(102, 204, 153, 1)',
        fill: false
      },
      {
        label: 'Deaths',
        data: deaths,
        backgroundColor: 'rgba(153, 153, 153, 0.2)',
        borderColor: 'rgba(153, 153, 153, 1)',
        fill: false
      },
      {
        label: 'Active',
        data: active,
        backgroundColor: 'rgba(204, 102, 102, 0.2)',
        borderColor: 'rgba(204, 102, 102, 1)',
        fill: false
      }
    ]
  }
  /*
    console.log("----- active: ", active)
    console.log("----- cases: ", cases)
    console.log("----- dates: ", dates)
    console.log("----- deaths: ", deaths)
    console.log("----- recovered: ", recovered)
    console.log("----- lineChartData: ", lineChartData)
    */
  return lineChartData
}

const parseAvaccine = (props: GetTimeseriesResponse): any => {
  //console.log("---- parseAvaccine: ", props)

  const aVaccine: number[] = []
  const cumulativeAvaccine: number[] = []
  const dates: string[] = []
  const province: string[] = []
  props.avaccine?.map((item: GetTimeseriesAvaccineBody) => {
    aVaccine.push(item.avaccine)
    cumulativeAvaccine.push(item.cumulative_avaccine)
    dates.push(item.date_vaccine_administered?.trim())
    province.push(item.province?.trim())
  })
  const lineChartData = {
    labels: dates,
    datasets: [
      {
        label: 'Vaccine Administered',
        data: cumulativeAvaccine,
        backgroundColor: 'rgba(102, 153, 204, 0.2)',
        borderColor: 'rgba(102, 153, 204, 1)',
        fill: false
      },
      {
        label: 'Daily Vaccine Administered',
        data: aVaccine,
        backgroundColor: 'rgba(102, 204, 153, 0.2)',
        borderColor: 'rgba(102, 204, 153, 1)',
        fill: false
      }
    ]
  }
  /*
    console.log("----- cumulativeCvaccine: ", cumulativeCvaccine)
    console.log("----- cVaccine: ", cVaccine)
    console.log("----- dates: ", dates)
    console.log("----- province: ", province)
    */
  return lineChartData
}

const parseCvaccine = (props: GetTimeseriesResponse): any => {
  //console.log("---- parseCvaccine: ", props)
  const cumulativeCvaccine: number[] = []
  const cVaccine: number[] = []
  const dates: string[] = []
  const province: string[] = []
  props.cvaccine?.map((item: GetTimeseriesCvaccineBody) => {
    cumulativeCvaccine.push(item.cumulative_cvaccine)
    cVaccine.push(item.cvaccine)
    dates.push(item.date_vaccine_completed?.trim())
    province.push(item.province?.trim())
  })
  const lineChartData = {
    labels: dates,
    datasets: [
      {
        label: 'Vaccine Completed',
        data: cumulativeCvaccine,
        backgroundColor: 'rgba(102, 153, 204, 0.2)',
        borderColor: 'rgba(102, 153, 204, 1)',
        fill: false
      },
      {
        label: 'Daily Vaccine Completed',
        data: cVaccine,
        backgroundColor: 'rgba(102, 204, 153, 0.2)',
        borderColor: 'rgba(102, 204, 153, 1)',
        fill: false
      }
    ]
  }
  /*
    console.log("----- cumulativeCvaccine: ", cumulativeCvaccine)
    console.log("----- cVaccine: ", cVaccine)
    console.log("----- dates: ", dates)
    console.log("----- province: ", province)
    */
  return lineChartData
}

const parseTesting = (props: GetTimeseriesResponse): any => {
  //console.log("---- parseTesting: ", props)
  const cumulativeTesting: number[] = []
  const dates: string[] = []
  const province: string[] = []
  const testing: number[] = []
  const testingInfo: string[] = []
  props.testing?.map((item: GetTimeseriesTestingBody) => {
    cumulativeTesting.push(item.cumulative_testing)
    dates.push(item.date_testing?.trim())
    province.push(item.province?.trim())
    testing.push(item.testing)
    testingInfo.push(item.date_testing?.trim())
  })
  const lineChartData = {
    labels: dates,
    datasets: [
      {
        label: 'Cumulative Testing',
        data: cumulativeTesting,
        backgroundColor: 'rgba(102, 153, 204, 0.2)',
        borderColor: 'rgba(102, 153, 204, 1)',
        fill: false
      },
      {
        label: 'Daily Testing',
        data: testing,
        backgroundColor: 'rgba(102, 204, 153, 0.2)',
        borderColor: 'rgba(102, 204, 153, 1)',
        fill: false
      }
    ]
  }
  /*
    console.log("----- cumulativeTesting: ", cumulativeTesting)
    console.log("----- dates: ", dates)
    console.log("----- province: ", province)
    console.log("----- testing: ", testing)
    console.log("----- testingInfo: ", testingInfo)
    */
  return lineChartData
}

export default CanadaCovid19Page
