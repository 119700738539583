/**
 * OpenCovidDataSource component that...
 *
 */
import * as React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons'
import { faAward, faDatabase } from '@fortawesome/free-solid-svg-icons'

export default function OpenCovidDataSource({ version = '' }) {
  return (
    <p>
      <small>
        <span>
          <FontAwesomeIcon icon={faDatabase} size="1x" aria-hidden="true" />
          {` `}
          <strong>Data Source:</strong>{' '}
          <a
            href="https://opencovid.ca/"
            rel="noopener noreferrer nofollow"
            target="_blank"
          >
            COVID-19 Canada Open Data Working Group (CCODWG)
          </a>
        </span>
        <br />
        <span>
          <FontAwesomeIcon icon={faAward} size="1x" aria-hidden="true" />
          {` `}
          <strong>Data Quality Score:</strong> N/A
        </span>
        <br />
        <span>
          <FontAwesomeIcon icon={faCalendarAlt} size="1x" aria-hidden="true" />
          {` `}
          <strong>Dataset Last Updated:</strong> {version ? version : 'N/A'}
        </span>
      </small>
    </p>
  )
}
