import { GetSummaryBody, GetSummaryResponse } from "./get-summary"
import { GetTimeseriesActiveBody, GetTimeseriesResponse } from "./get-timeseries"

/**
 *
 * @returns
 */
export const createEmptyGetSummaryResponse = (): GetSummaryResponse => ({
  summary: [],
  version: ""
})
/**
 *
 * @returns
 */
export const createGetSummaryResponse = <T extends Partial<GetSummaryResponse>>(initialValues: T): GetSummaryResponse & T => {
  return Object.assign(createEmptyGetSummaryResponse(), initialValues)
}

/**
 *
 * @returns
 */
export const createEmptyGetSummaryBody = (): GetSummaryBody => ({
  active_cases: null,
  active_cases_change: null,
  avaccine: null,
  cases: null,
  cumulative_avaccine: null,
  cumulative_cases: null,
  cumulative_cvaccine: null,
  cumulative_deaths: null,
  cumulative_dvaccine: null,
  cumulative_recovered: null,
  cumulative_testing: null,
  cvaccine: null,
  date: "",
  deaths: null,
  dvaccine: null,
  province: "",
  recovered: null,
  testing: null,
  testing_info: ""
})
/**
 *
 * @param initialValues
 * @returns
 */
export const createGetSummaryBody = <T extends Partial<GetSummaryBody>>(initialValues: T): GetSummaryBody & T => {
  return Object.assign(createEmptyGetSummaryBody(), initialValues)
}

/**
 *
 * @returns
 */
export const createEmptyGetTimeseriesResponse = (): GetTimeseriesResponse => ({
  active: [],
  cvaccine: [],
  testing: [],
  version: ""
})
/**
 *
 * @returns
 */
export const createGetTimeseriesResponse = <T extends Partial<GetTimeseriesResponse>>(initialValues: T): GetTimeseriesResponse & T => {
  return Object.assign(createEmptyGetTimeseriesResponse(), initialValues)
}

/**
 *
 * @returns
 */
export const createEmptyGetTimeseriesActiveBody = (): GetTimeseriesActiveBody => ({
  active_cases: null,
  active_cases_change: null,
  cumulative_cases: null,
  cumulative_deaths: null,
  cumulative_recovered: null,
  date_active: "",
  province: ""
})
/**
 *
 * @param initialValues
 * @returns
 */
export const createGetTimeseriesActiveBody = <T extends Partial<GetTimeseriesActiveBody>>(initialValues: T): GetTimeseriesActiveBody & T => {
  return Object.assign(createEmptyGetTimeseriesActiveBody(), initialValues)
}
